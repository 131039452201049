<template>
  <div class="order-page">
    <!-- 輪播圖 -->
    <div class="tw-carousel mb-3" v-if="pictureLinks.length > 0">
      <Carousel
        :items-to-show="currentViewSize.width >= 768 ? 3 : 1"
        :wrap-around="true"
        :autoplay="5000"
      >
        <Slide v-for="(data, index) in pictureLinks" :key="data.id">
          <div class="carousel__item">
            <div
              class="carousel-img pointer"
              :style="`background: url(${data.url}) no-repeat center center; background-size: contain;`"
              @click="showImage(data)"
            ></div>
            <!-- 下載圖片用 -->
            <img class="d-none" :id="`Pic_${index}`" :src="data.url" :alt="data.url">
          </div>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination class="not-show-scroll" style="overflow: scroll;"/>
        </template>
      </Carousel>
      <!-- 自製圖片放大加輪播 -->
      <ImageCarousel
        :currentImage="currentImage"
        :images="pictureLinks"
      ></ImageCarousel>
      <button class="tw-btn tw-btn-success" @click="downloadPictures" v-if="pictureLinks.length > 0 && !checkIsMobileDevice">一鍵下載圖片</button>
    </div>
    <!-- 商品內容 -->
    <div class="top-part p-0 p-md-2">
      <!-- 按鈕 -->
      <div class="text-end" v-if="currentRoute === 'C_OrderPage'">
        <button class="tw-btn tw-btn-secondary me-2" @click="goBack">
          返回
        </button>
        <button
          class="tw-btn tw-btn-success"
          v-if="p_shop !== '{}' && cloneMerchToStore"
          @click="showModal('cloneMerchToStore')"
        >
          一鍵複製
        </button>
      </div>
      <!-- 導覽 -->
      <ul class="navigation">
        <li
          :class="{
            active: currentPage === 'nowOrder',
            'border-bottom-0': currentPage === 'nowOrder',
          }"
        >
          <p @click="changePage('nowOrder')">立即下單</p>
        </li>
        <li
          :class="{
            active: currentPage === 'orderRecords',
            'border-bottom-0': currentPage === 'orderRecords',
          }"
        >
          <p @click="changePage('orderRecords')">購買紀錄</p>
        </li>
      </ul>
      <!-- 內容 -->
      <div
        class="
          tw-container
          m-0
          border-start border-end border-bottom border-2
          mb-5
          p-md-3
        "
      >
        <!-- 立即下單 -->
        <div v-if="currentPage === 'nowOrder'">
          <!-- 商品內容 -->
          <div>
            <p class="tw-border-start fw-bolder">
              {{ merchandiseInfo.name }}
              ｜
              <span class="text-success" v-if="sellState === 'CANPREORDER'"
                >可預購</span
              >
              <span class="text-primary" v-else-if="sellState === 'STOCKONLY'"
                >僅供現貨</span
              >
              <span class="text-danger" v-else>限量</span>
            </p>
            <p class="fw-bolder text-secondary mt-2" v-if="merchandiseInfo.startSaleQuantity">
              成團數: {{ merchandiseInfo.startSaleQuantity }}單，
              <span v-if="currentStartSaleQuantity > 0"
                >再 {{ currentStartSaleQuantity }} 單即可開團🔥</span
              >
              <span v-else>已開團拉🎉</span>
            </p>
            <p
              class="fw-bolder text-secondary mt-2"
              v-if="
                p_shop !== '{}' &&
                merchCardShowSellQuantity !== 'doNotShow' &&
                hotQuantity >= parseInt(merchCardShowSellQuantity)
              "
            >
              過往銷量: {{ hotQuantity }}
            </p>
            <hr />
          </div>
          <!-- 可預購 -->
          <div v-if="sellState === 'CANPREORDER'">
            <div class="row">
              <!-- 主要款式 -->
              <div class="col-md-6 p-0 pe-md-2">
                <table class="table table-hover">
                  <thead>
                    <tr class="text-start">
                      <th colspan="3">主要款式</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="data in canPreOrderStyles.mainStyles"
                      :key="data.id"
                    >
                      <tr class="text-start">
                        <td colspan="3">
                          <div class="select-radio">
                            <label>
                              <input
                                type="radio"
                                name="mainStyle"
                                :value="data"
                                v-model="selectStyle.mainStyle"
                                @change="checkRemainingStock"
                              />
                              <span class="radio-style">{{ data.name }}</span>
                            </label>
                          </div>
                          <span class="text-secondary fw-bolder"
                            >原價 NT${{ data.price }}元</span
                          >
                        </td>
                      </tr>
                      <template
                        v-if="
                          p_shop !== '{}' &&
                          ((showDiscounts === 'clickToShow' && selectStyle.mainStyle.id === data.id) ||
                          showDiscounts === 'showAll')
                        "
                      >
                        <tr
                          class="fw-bolder"
                          v-if="data.merchandiseDiscounts.length > 0"
                        >
                          <td>優惠數量</td>
                          <td>優惠價格</td>
                          <td>資格限制</td>
                        </tr>
                        <template
                          v-for="discount in data.merchandiseDiscounts"
                          :key="discount.id"
                        >
                          <tr>
                            <td>
                              <span
                                v-if="
                                  discount.minQuantity && discount.maxQuantity
                                "
                                >{{ discount.minQuantity }}~{{
                                  discount.maxQuantity
                                }}</span
                              >
                              <span v-else>{{ discount.minQuantity }}以上</span>
                            </td>
                            <td>{{ discount.price }}元</td>
                            <td class="text-danger">
                              <span v-if="discount.storeVipId">
                                {{ discount.storeVip.name }}以上可購買
                              </span>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              <!-- 其他細項 -->
              <div class="col-md-6 p-0">
                <table class="table table-hover">
                  <thead>
                    <tr class="text-start">
                      <th>其他細項</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- 細項一 -->
                    <tr
                      class="text-start fw-bolder"
                      v-if="canPreOrderStyles.oneStyles.length > 0"
                    >
                      <td>
                        <p class="mb-2">細項一</p>
                        <div
                          class="select-radio d-inline-block my-3"
                          v-for="data in canPreOrderStyles.oneStyles"
                          :key="data.id"
                        >
                          <label>
                            <input
                              type="radio"
                              name="oneStyle"
                              :value="data"
                              v-model="selectStyle.oneStyle"
                              @change="checkRemainingStock"
                            />
                            <span class="radio-style">{{ data.name }}</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <!-- 細項二 -->
                    <tr
                      class="text-start fw-bolder"
                      v-if="canPreOrderStyles.twoStyles.length > 0"
                    >
                      <td>
                        <p class="mb-2">細項二</p>
                        <div
                          class="select-radio d-inline-block my-3"
                          v-for="data in canPreOrderStyles.twoStyles"
                          :key="data.id"
                        >
                          <label>
                            <input
                              type="radio"
                              name="twoStyle"
                              :value="data"
                              v-model="selectStyle.twoStyle"
                              @change="checkRemainingStock"
                            />
                            <span class="radio-style">{{ data.name }}</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <!-- 細項三 -->
                    <tr
                      class="text-start fw-bolder"
                      v-if="canPreOrderStyles.threeStyles.length > 0"
                    >
                      <td>
                        <p class="mb-2">細項三</p>
                        <div
                          class="select-radio d-inline-block my-3"
                          v-for="data in canPreOrderStyles.threeStyles"
                          :key="data.id"
                        >
                          <label>
                            <input
                              type="radio"
                              name="threeStyle"
                              :value="data"
                              v-model="selectStyle.threeStyle"
                              @change="checkRemainingStock"
                            />
                            <span class="radio-style">{{ data.name }}</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <!-- 細項四 -->
                    <tr
                      class="text-start fw-bolder"
                      v-if="canPreOrderStyles.fourStyles.length > 0"
                    >
                      <td>
                        <p class="mb-2">細項四</p>
                        <div
                          class="select-radio d-inline-block my-3"
                          v-for="data in canPreOrderStyles.fourStyles"
                          :key="data.id"
                        >
                          <label>
                            <input
                              type="radio"
                              name="fourStyle"
                              :value="data"
                              v-model="selectStyle.fourStyle"
                              @change="checkRemainingStock"
                            />
                            <span class="radio-style">{{ data.name }}</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <!-- 數量確認 -->
            <div>
              <p class="alert alert-primary fw-bolder mb-3">
                <p class="mb-2" v-if="p_shop !== '{}'&& showStock">庫存: {{ remainingStock }}</p>
                選擇款式:
                <span v-if="selectStyle.mainStyle">
                  {{ selectStyle.mainStyle.name }}&ensp;
                </span>
                <span v-if="selectStyle.oneStyle">
                  {{ selectStyle.oneStyle.name }}&ensp;
                </span>
                <span v-if="selectStyle.twoStyle">
                  {{ selectStyle.twoStyle.name }}&ensp;
                </span>
                <span v-if="selectStyle.threeStyle">
                  {{ selectStyle.threeStyle.name }}&ensp;
                </span>
                <span v-if="selectStyle.fourStyle">
                  {{ selectStyle.fourStyle.name }}&ensp;
                </span>
              </p>
              <div>
                <!-- 數量 -->
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="CanPreOrderQuantity"
                    placeholder="購買數量"
                    min="0"
                    v-model="canPreOrderData.quantity"
                    @input="countFinalPrice('singleAdd')"
                    pattern="[0-9]*"
                    oninput="this.value=this.value.replace(/\D/g,'')"
                  />
                  <label for="CanPreOrderQuantity">購買數量</label>
                </div>
                <!-- 單價 -->
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="CanPreOrderPrice"
                    placeholder="單價"
                    disabled
                    :value="canPreOrderData.price"
                  />
                  <label for="CanPreOrderPrice">單價</label>
                </div>
                <!-- 備註 -->
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="備註"
                    id="CanPreOrderNote"
                    maxlength="300"
                    v-model="canPreOrderData.note"
                  ></textarea>
                  <label for="CanPreOrderNote">備註(300字)</label>
                </div>
                <hr />
                <p class="text-danger fw-bolder tw-text-size20">
                  總金額: NT${{ canPreOrderData.finalPrice }}元
                </p>
              </div>
            </div>
            <!-- 按鈕 -->
            <div class="text-end">
              <button
                class="tw-btn tw-btn-success me-2"
                @click="showModal('batchAddOrder')"
              >
                批次購買
              </button>
              <button
                class="tw-btn tw-btn-success me"
                @click="createOrderForCanPreOrder"
              >
                購買
              </button>
            </div>
          </div>
          <!-- 僅供現貨 -->
          <div v-else-if="sellState === 'STOCKONLY'">
            <!-- 內容 -->
            <div
              class="accordion"
              id="StockOnlyAccordion"
              v-if="stockOnly.size > 0"
            >
              <div
                class="accordion-item"
                v-for="data in stockOnly"
                :key="data[0]"
              >
                <h2 class="accordion-header" :id="`heading${data[0]}`">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse${data[0]}`"
                    aria-expanded="true"
                    :aria-controls="`collapse${data[0]}`"
                  >
                    {{ data[1].mainStyle.name }}，原價: NT${{
                      data[1].mainStyle.price
                    }}元
                  </button>
                </h2>
                <div
                  :id="`collapse${data[0]}`"
                  class="accordion-collapse collapse show"
                  :aria-labelledby="`heading${data[0]}`"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-1">
                    <!-- 優惠 -->
                    <div
                      class="table-responsive"
                      v-if="data[1].mainStyle.merchandiseDiscounts.length > 0"
                    >
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>優惠數量</th>
                            <th>優惠價格</th>
                            <th>資格限制</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="discount in data[1].mainStyle
                              .merchandiseDiscounts"
                            :key="discount.id"
                          >
                            <td>
                              <span
                                v-if="
                                  discount.minQuantity && discount.maxQuantity
                                "
                                >{{ discount.minQuantity }}~{{
                                  discount.maxQuantity
                                }}</span
                              >
                              <span v-else>{{ discount.minQuantity }}以上</span>
                            </td>
                            <td>{{ discount.price }}元</td>
                            <td class="text-danger">
                              <span v-if="discount.storeVipId">
                                {{ discount.storeVip.name }}以上可購買
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- 下單列表 -->
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th style="min-width: 150px">款式</th>
                            <th>庫存</th>
                            <th>單價</th>
                            <th>數量</th>
                            <th>備註</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="style in data[1].styles" :key="style.id">
                            <td>{{ style.styleName }}</td>
                            <td>{{ style.remainingStock }}</td>
                            <td>{{ data[1].currentPrice }}</td>
                            <td>
                              <input
                                onwheel="this.blur()"
                                type="number"
                                min="0"
                                :max="style.remainingStock"
                                v-model="style.orderQuantity"
                                @input="countFinalPrice('stockOnly')"
                                pattern="[0-9]*"
                                oninput="this.value=this.value.replace(/\D/g,'')"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                maxlength="300"
                                placeholder="備註(300字)"
                                v-model="style.note"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-danger fw-bolder" v-else>目前尚無現貨!</p>
            <!-- 按鈕 -->
            <div class="text-end mt-3" v-if="stockOnly.size > 0">
              <button
                class="tw-btn tw-btn-success"
                @click="createOrderForStockOnly('stockOnly')"
              >
                購買
              </button>
            </div>
          </div>
          <!-- 限量 -->
          <div v-else>
            <!-- 內容 -->
            <div
              class="accordion"
              id="LimitedAccordion"
              v-if="limited.size > 0"
            >
              <div
                class="accordion-item"
                v-for="data in limited"
                :key="data[0]"
              >
                <h2 class="accordion-header" :id="`heading${data[0]}`">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse${data[0]}`"
                    aria-expanded="true"
                    :aria-controls="`collapse${data[0]}`"
                  >
                    {{ data[1].mainStyle.name }}，原價: NT${{
                      data[1].mainStyle.price
                    }}元
                  </button>
                </h2>
                <div
                  :id="`collapse${data[0]}`"
                  class="accordion-collapse collapse show"
                  :aria-labelledby="`heading${data[0]}`"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-1">
                    <!-- 優惠 -->
                    <div
                      class="table-responsive"
                      v-if="data[1].mainStyle.merchandiseDiscounts.length > 0"
                    >
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>優惠數量</th>
                            <th>優惠價格</th>
                            <th>資格限制</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="discount in data[1].mainStyle
                              .merchandiseDiscounts"
                            :key="discount.id"
                          >
                            <td>
                              <span
                                v-if="
                                  discount.minQuantity && discount.maxQuantity
                                "
                                >{{ discount.minQuantity }}~{{
                                  discount.maxQuantity
                                }}</span
                              >
                              <span v-else>{{ discount.minQuantity }}以上</span>
                            </td>
                            <td>{{ discount.price }}元</td>
                            <td class="text-danger">
                              <span v-if="discount.storeVipId">
                                {{ discount.storeVip.name }}以上可購買
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- 下單列表 -->
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th style="min-width: 150px">款式</th>
                            <th>限量</th>
                            <th>單價</th>
                            <th>數量</th>
                            <th>備註</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="style in data[1].styles" :key="style.id">
                            <td>{{ style.styleName }}</td>
                            <td>{{ style.remainingStock }}</td>
                            <td>{{ data[1].currentPrice }}</td>
                            <td>
                              <input
                                onwheel="this.blur()"
                                type="number"
                                min="0"
                                :max="style.remainingStock"
                                v-model="style.orderQuantity"
                                @input="countFinalPrice('limited')"
                                pattern="[0-9]*"
                                oninput="this.value=this.value.replace(/\D/g,'')"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                maxlength="300"
                                placeholder="備註(300字)"
                                v-model="style.note"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-danger fw-bolder" v-else>目前尚無限量!</p>
            <!-- 按鈕 -->
            <div class="text-end mt-3" v-if="limited.size > 0">
              <button
                class="tw-btn tw-btn-success"
                @click="createOrderForStockOnly('limited')"
              >
                購買
              </button>
            </div>
          </div>
          <hr />
          <!-- 商品描述 -->
          <div class="fw-bolder">
            <p class="tw-border-start mb-3">商品描述:</p>
            <div v-html="merchandiseInfo.description" style="line-height: 25px"></div>
          </div>
        </div>
        <!-- 購買紀錄 -->
        <div v-else>
          <p class="tw-border-start fw-bolder tw-text-size20 mb-3">購買紀錄</p>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>購買時間</th>
                  <th>購買款式</th>
                  <th>購買數量</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in orderRecords.data" :key="data.id">
                  <td>
                    {{
                      $methods
                        .moment(data.createTime)
                        .format("YYYY-MM-DD HH:mm:ss")
                    }}
                  </td>
                  <td>
                    <span
                      v-for="style in data.merchandiseStyles"
                      :key="style.id"
                    >
                      {{ style.name }}&ensp;
                    </span>
                  </td>
                  <td>{{ data.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 可預購 > 批次購買的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchAddOrderModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">批次購買</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul>
            <li
              class="table-responsive"
              v-for="data in batchStyles"
              :key="data[0]"
            >
              <div class="mb-2">
                <p class="mb-2 fw-bolder tw-text-size20">
                  {{ data[1].mainStyle.name }}
                </p>
                <ul v-if="data[1].mainStyle.merchandiseDiscounts.length > 0">
                  <li
                    class="mb-1"
                    v-for="discount in data[1].mainStyle.merchandiseDiscounts"
                    :key="discount.id"
                  >
                    購買:
                    <span>
                      <span v-if="discount.minQuantity && discount.maxQuantity"
                        >{{ discount.minQuantity }} ~
                        {{ discount.maxQuantity }}個</span
                      >
                      <span v-if="discount.minQuantity && !discount.maxQuantity"
                        >{{ discount.minQuantity }}以上</span
                      > </span
                    >， 單價: {{ discount.price }}元
                    <span class="text-danger" v-if="discount.storeVipId">
                      ({{ discount.storeVip.name }})以上可購買
                    </span>
                  </li>
                </ul>
              </div>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th style="min-width: 150px">款式</th>
                    <th v-if="p_shop !== '{}' && showStock">庫存</th>
                    <th>單價</th>
                    <th>數量</th>
                    <th>備註</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="style in data[1].styles" :key="style.id">
                    <td>{{ style.styleName }}</td>
                    <td v-if="p_shop !== '{}' && showStock">{{ style.remainingStock }}</td>
                    <td>{{ data[1].currentPrice }}</td>
                    <td>
                      <input
                        onwheel="this.blur()"
                        type="number"
                        min="0"
                        v-model="style.orderQuantity"
                        @input="countFinalPrice('batchStyles')"
                        pattern="[0-9]*"
                        oninput="this.value=this.value.replace(/\D/g,'')"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        maxlength="300"
                        v-model="style.note"
                        placeholder="備註(300字)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createOrderForBatchStyles"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 一鍵複製 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="cloneMerchToStoreModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">一鍵複製</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-primary mb-3" role="alert">
            <p>1. 此功能會將本頁的商品複製至以下所選擇的個人賣場</p>
          </div>
          <!-- 個人賣場選單 -->
          <ul>
            <template v-for="data in ownerStores.data" :key="data.storeId">
              <li v-if="!data.store.deleted">
                <div class="form-check p-0">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="selectCloneStore"
                    :id="`SelectCloneStore${data.storeId}`"
                    :value="data.storeId"
                    v-model="cloneToStore"
                  />
                  <label
                    class="form-check-label"
                    :for="`SelectCloneStore${data.storeId}`"
                  >
                    {{ data.store.name }}
                  </label>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="cloneMerchandiseToStore"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from 'vuex'
// shot
import html2canvas from "html2canvas";
// carousel
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import ImageCarousel from '../modal/ImageCarousel.vue'
// api
import { lineLoginWithoutReg } from '@/methods/API/lineLoginWithoutReg'
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

// methos
import { handleImageHash2Url } from "@/methods/mixins/imageHash2Url"

export default {
  components: {Carousel, Slide, Pagination, Navigation, ImageCarousel},
  data() {
    return {
      // modal
      batchAddOrderModal: {},
      cloneMerchToStoreModal: {},
      // data
      currentRoute: 'C_OrderPage',
      storeId: 0,
      serverToken: '',
      merchandiseId: 0,
      sellState: 'CANPREORDER',
      // 紀錄目前視窗寬高
      currentViewSize: {
        width: 0,
        height: 0,
      },
      currentPage: 'nowOrder',
      // 賣場資訊
      storeInfo: {},
      // 賣場分類
      categories: [],
      // 顧客資訊
      participantInfo: {},
      hasReceivingInfo: false,
      // 商品資訊
      merchandiseInfo: {},
      // 商品輪播
      pictureLinks: [],
      currentImage: null,
      // 主要款式優惠
      mainStylesWithDiscounts: [],
      // 可預購資料
      remainingStock: 0, // 可預購 > 選擇款式 > 顯示對應的庫存(可配數)
      canPreOrderStyles: {
        mainStyles: [],
        oneStyles: [],
        twoStyles: [],
        threeStyles: [],
        fourStyles: []
      },
      selectStyle: {
        mainStyle: {},
        oneStyle: {},
        twoStyle: {},
        threeStyle: {},
        fourStyle: {}
      },
      canPreOrderData: {
        quantity: 0,
        price: 0,
        note: '',
        finalPrice: 0
      },
      // 複製到的賣場
      cloneToStore: '',
      // 所有款式
      styles: [],
      // 平整化
      batchStyles: [],
      // 僅供現貨
      stockOnly: [],
      // 限量
      limited: [],
      // 購買紀錄
      orderRecords: {
        loading: false,
        data: []
      },
      // 目前成團數
      currentStartSaleQuantity: null,
      // 目前售出數
      hotQuantity: 0,
      // 判斷是否為行動裝置
      checkIsMobileDevice: false,
      // * Line 免註冊
      queueId: 0,
      groupLinkId: 0,
      lineUserId: 0,
    }
  },
  mixins: [handleImageHash2Url],
  created() {
    this.initialization()
  },
  mounted() {
    // 紀錄目前視窗寬高
    this.currentViewSize.width = window.innerWidth;
    this.currentViewSize.height = window.innerHeight;
    // 監聽
    window.onresize = () => {
      this.currentViewSize.width = window.innerWidth;
      this.currentViewSize.height = window.innerHeight;
    };
    this.createModals(['batchAddOrderModal','cloneMerchToStoreModal'])
  },
  watch: {
    'selectStyle.mainStyle': {
      handler(val, oldVal) {
        this.canPreOrderData = {
          quantity: 1,
          price: 0,
          note: '',
          finalPrice: 0
        }
        this.countFinalPrice('singleAdd')
      },
      deep: true
    },
  },
  computed: {
    ...mapState('StoreList', {
      ownerStores: state => state.ownerStores,
    }),
    // 偏好 > 關閉賣場仍可下單
    closeShopCanStillOrder() {
      console.log('處發 closeShopCanStillOrder computed')
      return this.$store.state.Prefer.p_shop.closeShopCanStillOrder[0].selected
    },
    // 偏好 > 商品優惠依照顧客身分做顯示
    showPriceMode() {
      console.log('處發 showPriceMode computed')
      let showPriceMode = 'underMyVip'
      this.$store.state.Prefer.p_shop.orderPage.forEach(item => {
        if (item.selected) showPriceMode = item.value
      })
      return showPriceMode
    },
    // 偏好 > 複製商品
    cloneMerchToStore() {
      console.log('處發 cloneMerchToStore computed')
      return this.$store.state.Prefer.p_shop.cloneMerchToStore[0].selected
    },
    // 偏好 > 多件優惠呈現方式
    showDiscounts() {
      console.log('處發 showDiscounts computed')
      let showDiscounts = 'showAll'
      this.$store.state.Prefer.p_shop.showDiscounts.forEach(item => {
        if (item.selected) showDiscounts = item.value
      })
      return showDiscounts
    },
    // 偏好 > 顯示庫存
    showStock() {
      console.log('處發 showStock computed')
      return this.$store.state.Prefer.p_shop.showStock[0].selected
    },
    // 偏好 > 熱賣商品
    merchCardShowSellQuantity() {
      let sellQuantity = 'doNotShow'
      this.$store.state.Prefer.p_shop.sellQuantity.forEach(item => {
        if (item.selected) sellQuantity = item.value
      })
      return sellQuantity
    },
    /**
     * 偏好 > p_shop > 因為在 line 免註冊下單頁面，偏好無法精準控制在 mounted 之前完成
     * computed 在 created 就會掛載完成了，我在 mounted 掛在 html 若有用到 computed
     * 的話就會出錯，取不到偏好，所以先用 p_shop 來做判斷
     */
    p_shop() {
      return JSON.stringify(this.$store.state.Prefer.p_shop)
    },
  },
  methods: {
    initialization() {
      this.checkIsMobileDevice = this.$methods.checkIsMobileDevice()
      this.currentRoute = this.$route.name
      if (this.currentRoute === 'C_OrderPage') {
        // 顧客 > 下單頁面
        this.storeId = this.$route.params.storeId
        this.serverToken = this.$methods.getCookie('serverToken')
        this.merchandiseId = this.$route.query.merchandiseId
        this.getParticipantId()
      } else if (this.currentRoute === "LineMerchInfo") {
        // Line 直播下單頁面
        this.storeId = this.$route.query.storeId
        this.merchandiseId = this.$route.query.merchandiseId
        this.groupLinkId = this.$route.query.groupLinkId
        this.initLineSdk()
      } else {
        // Line 免註冊下單
        this.storeId = this.$route.query.storeId
        this.queueId = this.$route.query.queueId
        this.groupLinkId = this.$route.query.groupLinkId
        this.initLineSdk()
      }
    },
    showModal(status) {
      // 開 modal
      if (status === 'batchAddOrder') {
        // 可預購 > 批次購買
        // 檢查是否需要收件資訊
        if (this.storeInfo.receivingInfoNeeded && !this.hasReceivingInfo) return this.SweetAlert('other', '您需要有任一筆收件資訊才能下單')
        this.batchAddOrderModal.show()
      } else if (status === 'cloneMerchToStore') {
        console.log(this.ownerStores)
        if (this.ownerStores.loading) return this.SweetAlert('other', '請稍後~正在為您準備賣場列表')
        if (this.ownerStores.data.length === 0) return this.SweetAlert('other', '此功能需要擁有個人賣場能使用')
        this.cloneToStore = ''
        this.cloneMerchToStoreModal.show()
      }
    },
    goBack() {
      if (this.currentRoute === 'C_OrderPage') return this.$router.push(this.$methods.go('customerOrderPage', {merchandiseId: this.merchandiseId}))
      else return window.close()
    },
    // * Line 免註冊
    async initLineSdk() {
      try {
        let liffId = process.env.VUE_APP_PUSHORDER_PAGE_LINE_LIFFID;
        if (this.currentRoute === "LineMerchInfo") liffId = process.env.VUE_APP_MERCHANDISE_INFO_LINE_LIFFID;

        this.$methods.switchLoading('show')
        await liff.init({ liffId: liffId})
        console.log(`LIFF init, liff.isLoggedIn = ${liff.isLoggedIn()}`)
        const context = liff.getContext();
        console.log(context)
        if (context.type !== 'external') this.lineUserId = context.userId;
        console.log(`groupLinkId: ${this.groupLinkId}`)
        console.log(`lineUserId: ${this.lineUserId}`)
        this.lineLogin()
      } catch (err) {
        console.error(err)
        this.$methods.switchLoading('hide')
        alert('尚未取得 Line 相關資訊')
      }
    },
    lineLogin() {
      const result = lineLoginWithoutReg(this.groupLinkId, this.lineUserId)
      result.then(res => {
        if (res.code === '200') {
          console.log(res)
          this.serverToken = res.data
          this.getParticipantId()
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取對列資料
    getPushData() {
      const vm = this
      this.$methods.switchLoading('show')
      const getPushDataApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 43,
          ids: [this.queueId],
          methods: '{}'
        },
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPushDataApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function (res) {
          console.log(res)
          if (res.code === '200') {
            const lineQueue = res.data[0].objects[0]
            vm.merchandiseId = lineQueue.merchandiseId
            vm.getMerchandiseInfo()
          }
        },
        error: function (err) {
          vm.$methods.switchLoading('hide')
          console.log(err)
          alert(err.message)
        },
      })
    },
    // * 顧客 > 下單頁面
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.storeId,
        serverToken: this.serverToken
      })
    },
    // 取得 participantId
    getParticipantId() {
      this.getPrefer()
      this.$methods.switchLoading('show')
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          const participantInfo = res.data
          this.participantInfo = participantInfo
          // 檢查此顧客是否有權限進入
          if (this.participantInfo.deleted || this.participantInfo.forbidden) {
            alert('您尚未有權限進入購買，可能被賣家刪除或禁止')
            this.goBack()
          }
          this.getParticipantInfo(participantInfo.id)
        }
      }).catch(err => {
        console.log(err.responseJSON)
        if (err.responseJSON.code === '400-3') {
          // 免註冊顧客第一次(從未下過單，在此賣場尚未建立過虛擬顧客)進來此頁面
          this.participantInfo = null
          // 免註冊用戶沒辦法自行設定收件資訊
          this.hasReceivingInfo = true
          this.getStoreInfo()
        } else {
          this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
        }
      })
    },
    // 取 participant 資訊
    getParticipantInfo(participantId) {
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`;
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 13,
          ids: [participantId],
          methods: '{getReceivingInfos}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: false,
        url: getParticipantInfoApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            vm.hasReceivingInfo = participantInfo.receivingInfos.length > 0
            vm.getStoreInfo()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getMerchClassifications{getCustomerGroups}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // 檢查偏好關閉賣場是否扔可以下單
            console.log(`closeShopCanStillOrder: ${vm.closeShopCanStillOrder}`)
            if (!storeInfo.opened && !vm.closeShopCanStillOrder) {
              vm.$methods.switchLoading('hide')
              alert('賣場已關閉')
              vm.goBack()
            }
            // 整理賣場分類
            vm.sortOutCategories(storeInfo)
            // 取得商品資訊
            if (vm.currentRoute === 'C_OrderPage' || vm.currentRoute === "LineMerchInfo") vm.getMerchandiseInfo()
            else vm.getPushData()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理賣場分類
    sortOutCategories(storeInfo) {
      this.categories = []
      const now = new Date() * 1
      const p_customerGroupId = this.participantInfo ? this.participantInfo.customerGroupId : null
      const p_storeVipId = this.participantInfo && this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      storeInfo.merchClassifications.forEach(item => {
        const openInterval = item.openInterval
        const enableTime = item.enableTime
        const expirationTime = item.expirationTime
        const categroyVipId = item.storeVipId ? item.storeVipId : 0
        const hidden = item.hidden
        let matchTheTimeInterval = true
        if (openInterval && enableTime && expirationTime) {
          matchTheTimeInterval = enableTime <= now && now <= expirationTime
        }
        let matchCustomerGroup = true
        if (p_customerGroupId && item.customerGroups.length > 0) {
          matchCustomerGroup = item.customerGroups.some(group => {
            return group.id === p_customerGroupId
          })
        }
        let matchVipId = p_storeVipId >= categroyVipId
        console.log(matchTheTimeInterval,matchCustomerGroup,matchVipId,!hidden)
        if (!this.participantInfo || (this.participantInfo && this.participantInfo.authority === 'CUSTOMER')) {
          if (matchTheTimeInterval && matchCustomerGroup && matchVipId && !hidden) this.categories.push(item)
        } else {
          if (matchTheTimeInterval && !hidden) this.categories.push(item)
        }
      });
    },
    // 取得商品資訊
    getMerchandiseInfo(sortOutWhitchOne) {
      this.$methods.switchLoading('show')
      const vm = this
      const getMerchandiseInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let methods = '{getMerchClassifications,getMerchandisePictureLinks,getMerchandiseStyles{getMerchandiseDiscounts{getStoreVip}},getMerchOrders{getMerchandiseStyles,getShippingQuantity},generateMerchandiseStylesStockJsonNode}'
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: methods
        }
      ]
      this.orderRecords = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchInfo = res.data[0].objects[0]
            if (sortOutWhitchOne === 'orderRecords') {
              // 整理購買紀錄
              vm.sortOutOrderRecords(merchInfo)
              vm.$methods.switchLoading('hide')
            } else {
              // 整理商品資訊
              vm.sortOutMerchandises(merchInfo)
              // 整理購買紀錄
              vm.sortOutOrderRecords(merchInfo)
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理商品
    sortOutMerchandises(merchandiseInfo) {
      // 初始化
      this.sellState = merchandiseInfo.sellState
      this.canPreOrderStyles = {
        mainStyles: [],
        oneStyles: [],
        twoStyles: [],
        threeStyles: [],
        fourStyles: []
      }
      this.stockOnly = []
      this.limited = []
      // 檢查商品在分類內是否符合這個顧客的權限
      const matchCategory =  this.categories.some(item => {
        return merchandiseInfo.merchClassifications.some(category => {
          return category.id === item.id
        })
      })
      if (!matchCategory && this.categories.length > 0 && merchandiseInfo.merchClassifications.length > 0) {
        alert('您的身分尚未符合此商品所屬的商品分類')
        return this.goBack()
      } else if (this.categories.length === 0 && merchandiseInfo.merchClassifications.length > 0) {
        alert('此商品所屬的分類已下架或是您的身分不符合此分類的權限')
        return this.goBack()
      }
      // 檢查是否被刪除
      if (merchandiseInfo.deleted) {
        alert('此商品已被刪除')
        return this.goBack()
      }
      // 檢查上下架
      if (!merchandiseInfo.ready) {
        alert('此商品已下架')
        return this.goBack()
      }
      // 檢查收單時間
      const now = new Date() * 1
      if (merchandiseInfo.deadLineTime && now > merchandiseInfo.deadLineTime) {
        alert('此商品收單時間已到')
        return this.goBack()
      }
      // 檢查 VIP
      const merchStoreVipId = merchandiseInfo.storeVipId ? merchandiseInfo.storeVipId : 0
      const participantVipId = this.participantInfo && this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      if (participantVipId < merchStoreVipId) {
        alert('您的 VIP 等級不夠')
        return this.goBack()
      }
      // 商品備註將"Enter"轉換成<br>
      console.log(merchandiseInfo.description)
      if (merchandiseInfo.description) {
        if (merchandiseInfo.description.match(/https:\/\/(www.youtube.com|m.youtube.com|youtu.be|youtube.com)(\/[\w-]+)*(\.\w+)?\??(\w+=\w+&?)*/gm)) {
          /**
           * yt 連結
           * www.youtube.com: 網頁版
           * m.youtube.com: 網頁版(手機)
           * youtu.be: App版
           */
          merchandiseInfo.description.match(/https:\/\/(www.youtube.com|m.youtube.com|youtu.be|youtube.com)(\/[\w-]+)*(\.\w+)?\??(\w+=\w+&?)*/gm).forEach(ytUrl => {
            try {
              let vedioId = ''
              let iframeYtUrl = ''
              // 先檢查有沒有 shorts
              if (ytUrl.match(/shorts\/.[^?]+/gm)) vedioId = ytUrl.match(/shorts\/.[^?]+/gm)[0].split("/")[1]
              else {
                // 在做一般影片
                if (ytUrl.match(/youtu.be/gm)) vedioId = ytUrl.split("/")[3]
                else vedioId = ytUrl.match(/v=.[^&]+/gm)[0].split('=')[1]
              }
              iframeYtUrl = `https://www.youtube.com/embed/${vedioId}`
              console.log(`iframeYtUrl: ${iframeYtUrl}`)
              merchandiseInfo.description = merchandiseInfo.description.replace(ytUrl, `<iframe src="${iframeYtUrl}" allowfullscreen style="width: 100%; height: 500px"></iframe>`)
            } catch (error) {
              console.log('跳過這個 yt 連結')
            }
          })
        } 
        if (merchandiseInfo.description.match(/https:\/\/(\.?\w+)+(\/[\w-]+)*(\.\w+)?\??(\w+=\w+&?)*/gm)) {
          // 一般連結
          console.log(merchandiseInfo.description)
          merchandiseInfo.description.match(/https:\/\/(\.?\w+)+(\/[\w-]+)*(\.\w+)?\??(\w+=\w+&?)*/gm).forEach(url => {
            console.log(url)
            if (!url.match(/www.youtube.com/gm)) merchandiseInfo.description = merchandiseInfo.description.replace(url, `<a class="text-break" href="${url}&origin=${process.env.VUE_APP_ORIGINURL}" target="_blank">${url}</a>`)
          })
        }
        merchandiseInfo.description = merchandiseInfo.description.replace(/(\r\n)|(\n)/g,"<br>")
      }
      console.log(merchandiseInfo.description)
      // 紀錄商品資訊
      this.merchandiseInfo = merchandiseInfo
      // 取得商品圖片
      merchandiseInfo.merchandisePictureLinks.forEach(img => {
        img.url = handleImageHash2Url(img.imageHash)
      })
      this.pictureLinks = merchandiseInfo.merchandisePictureLinks
      // 取得主要款式優惠
      this.mainStylesWithDiscounts = merchandiseInfo.merchandiseStyles.filter(item => {
        return item.hierarchy === 0
      })
      const p_storeVipId = this.participantInfo && this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      // 整理資料
      if (merchandiseInfo.sellState === 'CANPREORDER') {
        // 可預購
        // 先取得第一個主要款式 (未被刪除的)
        const d4MainStyleIndex = _.findIndex(merchandiseInfo.merchandiseStyles, function(style) { return !style.deleted })
        this.selectStyle = {
          mainStyle: merchandiseInfo.merchandiseStyles[d4MainStyleIndex],
          oneStyle: {},
          twoStyle: {},
          threeStyle: {},
          fourStyle: {}
        }
        this.styles = []
        merchandiseInfo.merchandiseStyles.forEach(item => {
          if (!item.deleted) {
            this.styles.push(item)
            if (item.hierarchy === 0) {
              // 判斷偏好 VIP 呈現模式
              item.merchandiseDiscounts = item.merchandiseDiscounts.filter(discount => {
                const d_storeVipId = discount.storeVipId ? discount.storeVipId : 0
                if (this.showPriceMode === 'underMyVip') {
                  return p_storeVipId >= d_storeVipId
                } else if (this.showPriceMode === 'onlyMyVip') {
                  return p_storeVipId === d_storeVipId
                } else if (this.showPriceMode === 'all') {
                  return item
                }
              })
              this.canPreOrderStyles.mainStyles.push(item)
            } else if (item.hierarchy === 1) {
              this.canPreOrderStyles.oneStyles.push(item)
            } else if (item.hierarchy === 2) {  
              this.canPreOrderStyles.twoStyles.push(item)
            } else if (item.hierarchy === 3) {
              this.canPreOrderStyles.threeStyles.push(item)
            } else if (item.hierarchy === 4) {
              this.canPreOrderStyles.fourStyles.push(item)
            }
          }
        })
      }

      // merchandiseStylesStockJsonNode
      let sortoutMap = new Map()
      merchandiseInfo.merchandiseStylesStockJsonNode.forEach((item, index) => {
        // 檢查款式是否有被刪除
        let styleHasDeleted = item.styles.some(style => {
          return style.deleted
        })
        // 紀錄剩餘庫存(僅供現貨，限量)
        let remainingStock = 0
        if (merchandiseInfo.sellState === 'CANPREORDER' && item.stock && Math.max(item.stock.quantity - item.stock.merchOrderQuantity, 0) > 0) remainingStock = Math.max(item.stock.quantity - item.stock.merchOrderQuantity, 0)
        else if (merchandiseInfo.sellState === 'STOCKONLY' && item.stock && item.stock.stockOnlyQuantity > 0) remainingStock = item.stock.stockOnlyQuantity
        else if (merchandiseInfo.sellState === 'LIMITED' && item.stock && Math.max(item.stock.limitedQuantity - item.stock.merchOrderQuantity, 0) > 0) remainingStock = Math.max(item.stock.limitedQuantity - item.stock.merchOrderQuantity, 0)
        // 若款式沒被刪除，就繼續執行
        if (!styleHasDeleted && (((merchandiseInfo.sellState === 'STOCKONLY' || merchandiseInfo.sellState === 'LIMITED') && remainingStock > 0) || merchandiseInfo.sellState === 'CANPREORDER')) {
          let styleName = ''
          let styleIds = []
          item.styles.forEach(item => {
            styleName += `${item.name} `
            styleIds.push(item.id)
          })
          let mainStyle = {}
          let mainStylesWithDiscounts = JSON.parse(JSON.stringify(this.mainStylesWithDiscounts))
          mainStylesWithDiscounts.some(style => {
            if (style.id === item.styles[0].id) {
              // 判斷偏好 VIP 呈現模式
              style.merchandiseDiscounts = style.merchandiseDiscounts.filter(discount => {
                const d_storeVipId = discount.storeVipId ? discount.storeVipId : 0
                if (this.showPriceMode === 'underMyVip') {
                  return p_storeVipId >= d_storeVipId
                } else if (this.showPriceMode === 'onlyMyVip') {
                  return p_storeVipId === d_storeVipId
                } else if (this.showPriceMode === 'all') {
                  return style
                }
              })
              return mainStyle = style
            }
          })

          // 整理 map
          if (!sortoutMap.has(item.styles[0].id)) {
            const object = {
              currentPrice: item.styles[0].price,
              mainStyle: mainStyle,
              styles: []
            }
            sortoutMap.set(item.styles[0].id, object)
          }
          sortoutMap.get(item.styles[0].id).styles.push({
            id: new Date() * 1 * index,
            orderQuantity: 0,
            note: '',
            styleName: styleName,
            styleIds: styleIds,
            remainingStock: remainingStock,
          })
        }
      })
      // 紀錄
      if (merchandiseInfo.sellState === 'CANPREORDER') {
        this.batchStyles = sortoutMap
        this.checkRemainingStock()
      }
      else if (merchandiseInfo.sellState === 'STOCKONLY') this.stockOnly = sortoutMap
      else if (merchandiseInfo.sellState === 'LIMITED') this.limited = sortoutMap
      this.$methods.switchLoading('hide')
    },
    // 開啟圖片
    showImage(image) {
      this.currentImage = null
      this.currentImage = {
        id: image.id
      }
    },
    // 計算總額
    countFinalPrice(status) {
      const p_storeVipId = this.participantInfo && this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      if (status === 'singleAdd') {
        // 可預購 > 單一購買
        this.mainStylesWithDiscounts.some(style => {
          if (style.id === this.selectStyle.mainStyle.id) {
            let currentPrice = style.price
            let currentQuantity = this.canPreOrderData.quantity ? this.canPreOrderData.quantity : 0
            style.merchandiseDiscounts.forEach(discount => {
              const minQuantity = discount.minQuantity
              const maxQuantity = discount.maxQuantity
              const d_storeVipId = discount.storeVipId ? discount.storeVipId : 0
              let matchQuantityRange = false
              if (minQuantity && maxQuantity) matchQuantityRange = minQuantity <= currentQuantity && currentQuantity <= maxQuantity
              else if (minQuantity && !maxQuantity) matchQuantityRange = minQuantity <= currentQuantity
              let matchStoreVipId = p_storeVipId >= d_storeVipId
              if (matchQuantityRange && matchStoreVipId) {
                if (discount.price < currentPrice) currentPrice = discount.price
              }
            })
            this.canPreOrderData.price = currentPrice
            return true
          }
        })
        this.canPreOrderData.finalPrice = this.$methods.numberToFixed(this.canPreOrderData.quantity * this.canPreOrderData.price)
      } else if (status === 'batchStyles') {
        // 可預購 > 批次購買
        for (const [key, value] of this.batchStyles) {
          let totalQuantity = 0
          value.styles.forEach((style, index) => {
            value.currentPrice = value.mainStyle.price
            const orderQuantity = style.orderQuantity ? style.orderQuantity : 0
            if (orderQuantity > 0) totalQuantity += orderQuantity
            if (index === value.styles.length - 1) {
              this.mainStylesWithDiscounts.forEach(mainStylesWithDiscount => {
                if (mainStylesWithDiscount.id === key) {
                  mainStylesWithDiscount.merchandiseDiscounts.forEach(discount => {
                    const minQuantity = discount.minQuantity
                    const maxQuantity = discount.maxQuantity
                    const d_storeVipId = discount.storeVipId ? discount.storeVipId : 0
                    let matchQuantityRange = false
                    if (minQuantity && maxQuantity) matchQuantityRange = minQuantity <= totalQuantity && totalQuantity <= maxQuantity
                    else if (minQuantity && !maxQuantity) matchQuantityRange = minQuantity <= totalQuantity
                    let matchStoreVipId = p_storeVipId >= d_storeVipId
                    if (matchQuantityRange && matchStoreVipId) {
                      if (discount.price < value.currentPrice) value.currentPrice = discount.price
                    }
                  })
                }
              })
            }
          })
        }
      } else if (status === 'stockOnly') {
        // 僅供現貨
        for (const [key, value] of this.stockOnly) {
          let totalQuantity = 0
          value.currentPrice = value.mainStyle.price
          value.styles.forEach((style, index) => {
            const orderQuantity = style.orderQuantity ? style.orderQuantity : 0
            if (orderQuantity > 0) totalQuantity += orderQuantity
            if (index === value.styles.length - 1) {
              this.mainStylesWithDiscounts.forEach(mainStylesWithDiscount => {
                if (mainStylesWithDiscount.id === key) {
                  mainStylesWithDiscount.merchandiseDiscounts.forEach(discount => {
                    const minQuantity = discount.minQuantity
                    const maxQuantity = discount.maxQuantity
                    const d_storeVipId = discount.storeVipId ? discount.storeVipId : 0
                    let matchQuantityRange = false
                    if (minQuantity && maxQuantity) matchQuantityRange = minQuantity <= totalQuantity && totalQuantity <= maxQuantity
                    else if (minQuantity && !maxQuantity) matchQuantityRange = minQuantity <= totalQuantity
                    let matchStoreVipId = p_storeVipId >= d_storeVipId
                    if (matchQuantityRange && matchStoreVipId) {
                      if (discount.price < value.currentPrice) value.currentPrice = discount.price
                    }
                  })
                }
              })
            }
          })
        }
      } else if (status === 'limited') {
        // 限量
        for (const [key, value] of this.limited) {
          let totalQuantity = 0
          value.currentPrice = value.mainStyle.price
          value.styles.forEach((style, index) => {
            const orderQuantity = style.orderQuantity ? style.orderQuantity : 0
            if (orderQuantity > 0) totalQuantity += orderQuantity
            if (index === value.styles.length - 1) {
              this.mainStylesWithDiscounts.forEach(mainStylesWithDiscount => {
                if (mainStylesWithDiscount.id === key) {
                  mainStylesWithDiscount.merchandiseDiscounts.forEach(discount => {
                    const minQuantity = discount.minQuantity
                    const maxQuantity = discount.maxQuantity
                    const d_storeVipId = discount.storeVipId ? discount.storeVipId : 0
                    let matchQuantityRange = false
                    if (minQuantity && maxQuantity) matchQuantityRange = minQuantity <= totalQuantity && totalQuantity <= maxQuantity
                    else if (minQuantity && !maxQuantity) matchQuantityRange = minQuantity <= totalQuantity
                    let matchStoreVipId = p_storeVipId >= d_storeVipId
                    if (matchQuantityRange && matchStoreVipId) {
                      if (discount.price < value.currentPrice) value.currentPrice = discount.price
                    }
                  })
                }
              })
            }
          })
        }
      }
    },
    // 整理購買紀錄 / 成團數目前數量
    sortOutOrderRecords(merchandiseInfo) {
      setTimeout(() => {
        this.currentStartSaleQuantity = null
        let finishOrders = 0
        this.hotQuantity = 0
        merchandiseInfo.merchOrders.forEach(item => {
          // 購買紀錄
          if (this.participantInfo && item.buyerId === this.participantInfo.id) this.orderRecords.data.push(item)
          // 成團數目前數量
          finishOrders += item.quantity - item.shippingQuantity
          // 目前售出數
          this.hotQuantity += item.quantity
        })
        console.log(merchandiseInfo.startSaleQuantity, finishOrders)
        if (merchandiseInfo.startSaleQuantity) this.currentStartSaleQuantity = merchandiseInfo.startSaleQuantity - finishOrders
        this.orderRecords.loading = false
      }, 1)
    },
    // 切換頁
    changePage(page) {
      // 如果切到購買紀錄記得再次更新資料，現在下單都沒有在抓資料
      if (page === 'orderRecords') this.getMerchandiseInfo('orderRecords')
      this.currentPage = page
    },
    // 可預購 > 點選款式 > 檢查剩餘庫存
    checkRemainingStock() {
      this.remainingStock = 0
      if (!this.showStock) return
      console.log('檢查剩餘庫存')
      // 先檢查款式是否勾選完整
      const pass = this.checkTheCompleteStyles()
      if (!pass) return
      // 先組合選到的款式 id
      let selectIds = []
      for (const [key, value] of Object.entries(this.selectStyle)) {
        console.log(key, value)
        if (JSON.stringify(value) !== "{}") selectIds.push(value.id)
      }
      // 再來跟庫存做比對
      for (const [key, value] of this.batchStyles) {
        console.log(key, value)
        value.styles.forEach(item => {
          if (item.remainingStock > 0 && JSON.stringify(selectIds) === JSON.stringify(item.styleIds)) {
            this.remainingStock = item.remainingStock
          }
        })
      }
    },
    // 可預購 > 檢查款式是否勾選完整
    checkTheCompleteStyles() {
      let pass = true
      let maxIndex = 0 // 款式上限
      let indexA = 0
      for (const [key, value] of Object.entries(this.canPreOrderStyles)) {
        if (value.length > 0) maxIndex = indexA
        indexA ++
      }
      let indexB = 0
      for (const [key, value] of Object.entries(this.selectStyle)) {
        console.log(maxIndex, indexB)
        if (maxIndex >= indexB) {
          if (JSON.stringify(value) === '{}') pass = false
        }
        indexB ++
      }
      return pass
    },
    // 可預購 > 建立訂單
    createOrderForCanPreOrder:_.debounce(function() {
      if (this.storeInfo.receivingInfoNeeded && !this.hasReceivingInfo) return this.SweetAlert('other', '您需要有任一筆收件資訊才能下單')
      // 檢查是否勾選完整
      const pass = this.checkTheCompleteStyles()
      if (!pass) return this.SweetAlert('other', '請將款式勾選完整')
      this.$methods.switchLoading('show')
      const vm = this;
      const createOrderForCanPreOrderApi = `${process.env.VUE_APP_API}/merchOrder/create`;
      const header = {
        authorization: this.serverToken
      };
      let merchandiseStyleIds = []
      for (const [key, value] of Object.entries(this.selectStyle)) {
        if (JSON.stringify(value) !== '{}') merchandiseStyleIds.push(value.id)
      }
      const data = [{
        merchOrder: {
          storeId: this.currentRoute === 'C_OrderPage' ? this.storeId : this.groupLinkId,
          merchandiseId: this.merchandiseId,
          buyerId: this.currentRoute === 'C_OrderPage' ? this.participantInfo.id : this.lineUserId,
          price: this.canPreOrderData.price,
          quantity: this.canPreOrderData.quantity,
          note: this.canPreOrderData.note
        },
        merchandiseStyleIds: merchandiseStyleIds
      }]
      console.log(data)
      $.ajax({
        type: "POST",
        async: true,
        url: createOrderForCanPreOrderApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.selectStyle = {
              mainStyle: vm.canPreOrderStyles.mainStyles[0],
              oneStyle: {},
              twoStyle: {},
              threeStyle: {},
              fourStyle: {}
            }
            vm.getMerchandiseInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      });
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    // 可預購 > 批次建立訂單
    createOrderForBatchStyles:_.debounce(function() {
      const vm = this;
      const createOrderForBatchStylesApi = `${process.env.VUE_APP_API}/merchOrder/create`;
      const header = {
        authorization: this.serverToken
      };
      let data = []
      for (const [key, value] of this.batchStyles) {
        value.styles.forEach(style => {
          const orderQuantity = style.orderQuantity ? style.orderQuantity : 0
          if (orderQuantity > 0) {
            data.push({
              merchOrder: {
                storeId: this.currentRoute === 'C_OrderPage' ? this.storeId : this.groupLinkId,
                merchandiseId: this.merchandiseId,
                buyerId: this.currentRoute === 'C_OrderPage' ? this.participantInfo.id : this.lineUserId,
                price: value.currentPrice,
                quantity: orderQuantity,
                note: style.note
              },
              merchandiseStyleIds: style.styleIds
            })
          }
        })
      }
      console.log(data)
      if (data.length === 0) return this.SweetAlert('other', '您尚未填寫任何數量')
      this.$methods.switchLoading('show')
      $.ajax({
        type: "POST",
        async: true,
        url: createOrderForBatchStylesApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.batchAddOrderModal.hide()
            vm.getMerchandiseInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      });
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    // 僅供現貨 / 限量 > 建立訂單
    createOrderForStockOnly:_.debounce(function(status) {
      // 檢查是否需要收件資訊
      if (this.storeInfo.receivingInfoNeeded && !this.hasReceivingInfo) return this.SweetAlert('other', '您需要有任一筆收件資訊才能下單')
      const vm = this;
      const createOrderForStockOnlyApi = `${process.env.VUE_APP_API}/merchOrder/create`;
      const header = {
        authorization: this.serverToken
      };
      let data = []
      let map = []
      if (status === 'stockOnly') map = this.stockOnly
      else map = this.limited
      for (const [key, value] of map) {
        value.styles.forEach(style => {
          const orderQuantity = style.orderQuantity ? style.orderQuantity : 0
          if (orderQuantity > 0) {
            data.push({
              merchOrder: {
                storeId: this.currentRoute === 'C_OrderPage' ? this.storeId : this.groupLinkId,
                merchandiseId: this.merchandiseId,
                buyerId: this.currentRoute === 'C_OrderPage' ? this.participantInfo.id : this.lineUserId,
                price: value.currentPrice,
                quantity: orderQuantity,
                note: style.note
              },
              merchandiseStyleIds: style.styleIds
            })
          }
        })
      }
      console.log(data)
      if (data.length === 0) return this.SweetAlert('other', '您尚未填寫任何數量')
      this.$methods.switchLoading('show')
      $.ajax({
        type: "POST",
        async: true,
        url: createOrderForStockOnlyApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getMerchandiseInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      });
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    // 複製商品
    cloneMerchandiseToStore() {
      if (!this.cloneToStore) return this.SweetAlert('other', '請選擇賣場')
      const merchandiseIds = this.merchandiseId
      const storeId = this.cloneToStore
      let ownerStoreIds = []
      this.ownerStores.data.forEach(item => {
        ownerStoreIds.push(item.storeId)
      })
      const copyMerchandiseUrl = `${process.env.VUE_APP_ORIGINURL}/seller/store/${storeId}/copyMerchandise?merchandiseIds=${merchandiseIds}&ownerStoreIds=${ownerStoreIds.toString()}`
      window.open(copyMerchandiseUrl, '_blank')
      this.cloneMerchToStoreModal.hide()
    },
    // 一鍵下載圖片 (若是行動裝置就不顯示)
    downloadPictures() {
      this.$methods.switchLoading('show')
      setTimeout(() => {
        this.pictureLinks.forEach((img, index, arr) => {
          setTimeout(() => {
            // 顯示 img 標籤
            $(`#Pic_${index}`).removeClass('d-none')
            // 取得該圖 img 標籤
            const imgTag = document.getElementById(`Pic_${index}`)
            console.log(imgTag)
            // 開始截圖
            html2canvas(imgTag, {
              backgroundColor: "#fff",
              useCORS: true,
              scale: 1,
            }).then((canvas) => {
              const downloadUrl = canvas.toDataURL("image/png")
              // 開始儲存圖片
              const now = this.$methods.moment(new Date()).format('YYYYMMDD-HHmmss')
              let alink = document.createElement("a")
              alink.href = downloadUrl
              alink.download = now
              alink.click()
              // 在將 img 隱藏
              $(`#Pic_${index}`).addClass('d-none')
              // 判斷是否做完
              if (index === arr.length - 1) this.$methods.switchLoading('hide')
            })
          }, 1000)
        })
      }, 500)
    },
  },
}
</script>